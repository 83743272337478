import { gatewayApi, globoTranslateServiceId } from 'constants/global-variables.js';
import { HttpMethods } from 'constants/enums.js';
import { getCompanyId, getUserInfo } from 'infrastructure/auth.js';
import { configurationTypeIds } from 'constants/integrationEnums.js';

export const globoAPI = async params => {
	try {
		const baseUrl = `/api/tenant-configurations/organizations/${getCompanyId()}/translation-services/${
			configurationTypeIds.GLOBO.id
		}`;
		const { data } = await gatewayApi.post(baseUrl, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getServices = async (baseUrl, location) => {
	const url = `${baseUrl}api/v2/workflow/services`;
	const params = { method: HttpMethods.GET, path: url, query: '', body: '', location };
	try {
		const res = await globoAPI(params);
		return res;
	} catch (error) {
		return { error };
	}
};

export const getGloboLanguages = async (baseUrl, location) => {
	const url = `${baseUrl}api/v2/workflow/services/${globoTranslateServiceId}/languages`;
	const params = { method: HttpMethods.GET, path: url, query: '', body: '', location };

	try {
		const res = await globoAPI(params);
		return res;
	} catch (error) {
		return { error };
	}
};

export const getAssignments = async (baseUrl, location) => {
	const url = `${baseUrl}api/v2/workflow/services/${globoTranslateServiceId}/assignments`;
	const params = { method: HttpMethods.GET, path: url, query: '', body: '', location };

	try {
		const res = await globoAPI(params);
		return res;
	} catch (error) {
		return { error };
	}
};

export const createAssignment = async (
	{ inviteUrl, sourceLanguage, targetLanguage, callbacks, baseUrl, questions = null },
	location
) => {
	const { firstName, lastName } = getUserInfo();
	const obj = {
		targetLanguage,
		name: `Doctor ${firstName} ${lastName} requested a translator`,
		sourceLanguage,
		callbacks,
		payload: {
			url: inviteUrl,
		},
		...(questions && { questions }),
	};
	const url = `${baseUrl}api/v2/workflow/services/${globoTranslateServiceId}/assignments`;
	const params = { method: HttpMethods.POST, path: url, query: '', body: JSON.stringify(obj), location };
	try {
		const res = await globoAPI(params);
		return res;
	} catch (error) {
		return { error };
	}
};

export const deleteAssignment = async ({ assignmentId, baseUrl }, location) => {
	const url = `${baseUrl}api/v2/workflow/services/${globoTranslateServiceId}/assignments/${assignmentId} `;
	const params = { method: HttpMethods.DELETE, path: url, query: '', body: '', location };
	try {
		const res = await globoAPI(params);
		return res;
	} catch (error) {
		return { error };
	}
};
